import { Button, Form, Input, Radio, InputNumber, Space } from 'antd';
  // Space
  // Select,
  // Cascader,
  // DatePicker,
  // TreeSelect,
  // Switch,
  // Checkbox,
  // Upload, 
// import { PlusOutlined } from '@ant-design/icons';
// import { InfoCircleOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';

// const { RangePicker } = DatePicker;
const { TextArea, Search } = Input;
const string = (s) =>  Zgg.string("addvoc_" + s);


const App = () => {
  const [form] = Form.useForm();
  const chooseTypeOther = () => { form.setFieldsValue({ type: { normal: 'other' } }) };
  const chooseSourceOther = () => { form.setFieldsValue({ source: { normal: 'other' } }) };
  const chooseMethodOther = () => { form.setFieldsValue({ method: { normal: 'other' } }) };
  const search = async(word) => {
    debugger
    const res = await fetch(`/search?query=${word}`);
    const index = 10 || await res.text();
    if (index) {
      form.setFieldsValue({ according: index });
    }
  };
  const onFinish = (values) => {
    const res = form.getFieldsValue();
    ["type", "source", "method"].forEach(section => {
      if (res[section].normal === 'other') {
        res[section] = "other: " + res[section].special;
      } else {
        res[section] = res[section].normal;
      }
    });
    res.meaning = `${res.meaning.n ? "n: " + res.meaning.n + "; " : ""}${res.meaning.vi ? "vi: " + res.meaning.vi + "; " : ""}${res.meaning.vt ? "vt: " + res.meaning.vt + "; " : ""}${res.meaning.adj ? "adj: " + res.meaning.adj + "; " : ""}${res.meaning.adv ? "adv: " + res.meaning.adv + "; " : ""}${res.meaning.other ? "other: " + res.meaning.other + ";" : ""}`;
    debugger
    console.log(res);
  };
  const reset = () => {
    form.setFieldsValue({ main: undefined});
    form.setFieldsValue({ meaning: undefined });
    form.setFieldsValue({ note: undefined });
    form.setFieldsValue({ note_extension: undefined });
    form.setFieldsValue({ according: undefined });
  };
  return (
    <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} form={form} 
      onFinish={(values) => onFinish(values)}
      // onFieldsChange={(changedValues, allValues) => onFieldsChange(changedValues, allValues)}
      initialValues = {{
        type: {
          normal: "vocabulary",
        },
        source: {
          normal: "default",
        },
        method: {
          normal: "root",
        },
      }}
    >
      
      <Form.Item
        label={string("main")}
        name="main"
        rules={[
          {
            required: true,
            message: 'This filed is required!',
          },
        ]}
      >
        <Input allowClear={React.createElement(CloseOutlined)} style={{ width: '150px' }}/>
      </Form.Item>

      <Form.Item
        label={string("meaning")}
        name="meaning"
        rules={[
          {
            required: true,
            message: 'This filed is required!',
          },
        ]}
      >
        <Input.Group compact>
          {["n", "vi", "vt", "adj", "adv", "other"].map((item) => {
            return (
              <div style={{display: "inline-block"}} key={item}>
                <div style={{width: "70px", display: "inline-block", verticalAlign: "-webkit-baseline-middle"}}>{item}: </div>
                <Form.Item name={['meaning', item]} style={{marginBottom: "1px", display: "inline-block"}}>
                  <Input allowClear={React.createElement(CloseOutlined)} style={{ width: "240px", left: "-20px" }}/>
                </Form.Item>
              </div>
            )
          })}
        </Input.Group>
      </Form.Item>

      <Form.Item label={string("type")} name="type" rules={[
        {
          required: true,
        },
      ]}>
        <Input.Group compact>
          <Form.Item name={['type', 'normal']} style={{marginBottom: "0px"}}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="vocabulary">{string("vocabulary")}</Radio.Button>
              <Radio.Button value="root">{string("root")}</Radio.Button>
              <Radio.Button value="prefix">{string("prefix")}</Radio.Button>
              <Radio.Button value="postfix">{string("postfix")}</Radio.Button>
              <Radio.Button value="other">{string("other")}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item name={['type', 'special']} style={{marginBottom: "0px"}}>
            <Input style={{ width: '80%' }} onChange={() => chooseTypeOther()} allowClear={React.createElement(CloseOutlined)}/>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label={string("source")} name="source">
        <Input.Group compact>
          <Form.Item name={['source', 'normal']} style={{marginBottom: "0px"}}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="default">{string("default")}</Radio.Button>
              <Radio.Button value="drama">{string("drama")}</Radio.Button>
              <Radio.Button value="life">{string("life")}</Radio.Button>
              <Radio.Button value="tiktok">{string("tiktok")}</Radio.Button>
              <Radio.Button value="dict">{string("dict")}</Radio.Button>
              <Radio.Button value="500root">{string("500root")}</Radio.Button>
              <Radio.Button value="other">{string("other")}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item name={['source', 'special']} style={{marginBottom: "0px"}}>
            <Input style={{ width: '150px' }} onChange={() => chooseSourceOther()} allowClear={React.createElement(CloseOutlined)}/>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label={string("method")} name="method">
        <Input.Group compact>
          <Form.Item name={['method', 'normal']} style={{marginBottom: "0px"}}>
            <Radio.Group buttonStyle="solid">
              <Radio value="root"> {string("root")}</Radio>
              <Radio value="life"> {string("life")}</Radio>
              <Radio value="special"> {string("special")}</Radio>
              <Radio value="odd"> {string("odd")}</Radio>
              <Radio value="other"> {string("other")}</Radio>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item name={['method', 'special']} style={{marginBottom: "0px"}}>
            <Input style={{ width: '150px' }} onChange={() => chooseMethodOther()} allowClear={React.createElement(CloseOutlined)}/>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label={string("note")} name="note">
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item label={string("note_extension")} name="note_extension">
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item label={string("according")}>
        <Form.Item name="according" style={{display: "inline-block"}}>
          <InputNumber min={1}/>
        </Form.Item>
        <Form.Item label={`${string("word")}: `} style={{display: "inline-block", position: "relative", left: "20px" }}>
          <Search
            enterButton={string("Search")}
            size="midium"
            label="xxx"
            onSearch={(word) => search(word)}
            style={{ width: '80%'}}
            allowClear={React.createElement(CloseOutlined)}
          />
        </Form.Item>
      </Form.Item>

      {/* <Form.Item label="Chekbox" name="disabled" valuePropName="checked">
          <Checkbox>Checkbox</Checkbox>
        </Form.Item>
      
      <Form.Item label="Radio">
        <Radio.Group>
          <Radio value="apple"> Apple </Radio>
          <Radio value="pear"> Pear </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Select">
        <Select>
          <Select.Option value="demo">Demo</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Field B"
        tooltip={{ title: 'Tooltip with customize icon', icon: <InfoCircleOutlined /> }}
      >
        <Input placeholder="input placeholder" />
      </Form.Item>

      <Form.Item label="TreeSelect">
        <TreeSelect
          treeData={[
            {
              title: 'Light',
              value: 'light',
              children: [
                {
                  title: 'Bamboo',
                  value: 'bamboo',
                },
              ],
            },
          ]}
        />
      </Form.Item>

      <Form.Item label="Cascader">
        <Cascader
          options={[
            {
              value: 'zhejiang',
              label: 'Zhejiang',
              children: [
                {
                  value: 'hangzhou',
                  label: 'Hangzhou',
                },
              ],
            },
          ]}
        />
      </Form.Item>

      <Form.Item label="DatePicker">
        <DatePicker />
      </Form.Item>
      <Form.Item label="RangePicker">
        <RangePicker />
      </Form.Item>
      <Form.Item label="InputNumber">
        <InputNumber />
      </Form.Item>
      <Form.Item label="TextArea">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item label="Switch" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Upload" valuePropName="fileList">
        <Upload action="/upload.do" listType="picture-card">
          <div>
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item label="Button">
        <Button>Button</Button>
      </Form.Item>

      <Form.Item label="Field A">
        <Input placeholder="input placeholder" />
      </Form.Item>
      <Form.Item label="Field B">
        <Input placeholder="input placeholder" />
      </Form.Item>*/}
      <Form.Item wrapperCol={{
        span: 14,
        offset: 4,
      }}>
        <Space size="large">
          <Button type="primary" htmlType="submit" /*onClick={() => submit()}*/>{string("Submit")}</Button>
          <Button type="primary" onClick={() => reset()}>{string("reset")}</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default App;