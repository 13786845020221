import React from 'react';
// import { Counter } from './features/counter/Counter';
import "antd/dist/antd.css";
import "./antd.css";
import { selectLanguage } from "./slices/languageSlice";
import { useSelector } from "react-redux";
import Layout from "./pages/Layout";
function App() {
  useSelector(selectLanguage);
  console.log("App render")
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;