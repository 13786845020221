/* eslint-disable no-extend-native */
window.Zgg = window.Zgg ?? {};
Zgg.string = (string, pattern) => {
  const res = Zgg.dictionary[Zgg.store?.getState().language.value || Zgg.response.language][string];
  if (!res) {
    console.debug(`cannot translate ${string} using Zgg.string`);
    return string;
  }
  if (typeof res === "function") {
    return res(pattern);
  }
  return res;
};
let temp = console.debug;
window.console.debug = (info) => {
  if (Zgg.flags.showConsole) {
    temp(info);
  }
}
Array.prototype.reci = function (num) {
  return this[this.length - num];
}
Array.prototype.equal = function (arr) {
  return JSON.stringify(this) === JSON.stringify(arr);
}