import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import languageReducer from '../slices/languageSlice';
import navigateReducer from '../slices/navigateSlice';

const store = configureStore({
  reducer: {
    counter: counterReducer,
    language: languageReducer,
    navigate: navigateReducer,
  },
});
console.log(store)
export { store };