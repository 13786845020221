import { Dropdown, Menu, Space } from 'antd';
import React from "react";
import { selectLanguage, changeLanguage } from "../slices/languageSlice";
import { useSelector, useDispatch } from "react-redux";
const languageMap = {
  Chinese: "中文",
  English: "English",
}
const items= [
  { key: 'English', label: "English" },
  { key: 'Chinese', label: "中文" },
];
const App = () => {
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();
  return (
    <Dropdown style={{marginLeft: "100px"}} overlay={<Menu onClick={ ({ key }) => dispatch(changeLanguage(key)) } items={items} />} placement="bottomRight">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={ (e) => e.preventDefault() }>
        <Space>
          {languageMap[language]}
        </Space>
      </a>
    </Dropdown>
  )
}

export default App;