// import React, { useState } from "react";
// import { useSelector, useDispatch } from "react-redux";

const Home = () => {
  return (
    <div>
      This is a Home page
    </div>
  );
};

export default Home;