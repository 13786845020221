import { Button, Form, Input, InputNumber, Radio, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import * as InterestCalculator from './InterestCalculator.js';

const { TextArea, Search } = Input;
const string = (s, pattern) =>  Zgg.string("investment_" + s, pattern);

const FormItem = (props) => {
  return (
    <Form.Item label={props.label}>
      <Form.Item name={props.name} noStyle>
        <InputNumber min={props.min || 0} style={{ width: '100px' }}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <span className="ant-form-text" style={{ marginLeft: 8 }}> {props.note} </span>
    </Form.Item>
  )
}
const App = () => {
  const [form] = Form.useForm();
  const [dataCalculated, setDataCalculated] = React.useState(false);
  const [render, setRender] = React.useState(false);
  const [loanLeft, setLoanLeft] = React.useState(0);
  const [REUpdatedValue, setREUpdatedValue] = React.useState(0);
  const [RELeftValue, setRELeftValue] = React.useState(0);
  const [cash, setCash] = React.useState(0);
  const [monthPayment, setMonthPayment] = React.useState(0);
  const [investmentResult, setInvestmentResult] = React.useState(0);
  const [investmentResultToNow, setInvestmentResultToNow] = React.useState(0);
  const [period, setPeriod] = React.useState(0);
  const [maintainFeeTotal, setMaintainFeeTotal] = React.useState(0);
  const [rentIncomeTotal, setRentIncomeTotal] = React.useState(0);
  const [case2Result, setCase2Result] = React.useState(0);
  const [case2ResultToNow, setCase2ResultToNow] = React.useState(0);
  const calc = (values) => {
    const data = InterestCalculator.calc({mortgageRate: values.mortgageRate, loan: values.REValue - values.cash});
    debugger
    setPeriod(values.period);
    const cash = values.cash;
    setCash(cash);
    const monthPayment = Number(data[0].total.replace(/\$\s?|(,*)/g, ''));
    setMonthPayment(monthPayment);
    let investmentResult = cash;
    for (let i = 0; i < values.period; i++) {
      investmentResult *= (1 + values.investmentRate/100);
      investmentResult += monthPayment*12;
    }
    setInvestmentResult(investmentResult);
    const investmentResultToNow = investmentResult / (1 + values.moneyValue/100) ** values.period;
    setInvestmentResultToNow(investmentResultToNow);
    const loanLeft = Number(data[values.period*12-1].engingLoan.replace(/\$\s?|(,*)/g, ''));
    setLoanLeft(loanLeft);
    const REUpdatedValue = values.REValue * (1 + values.REAppreciation/100) ** values.period;
    setREUpdatedValue(REUpdatedValue);
    const RELeftValue = REUpdatedValue - loanLeft;
    setRELeftValue(RELeftValue);
    let maintainFeeTotal = 0;
    for (let i = 0; i < values.period; i++) {
      maintainFeeTotal = maintainFeeTotal * (1 + values.moneyValue/100) + values.REValue * values.landTax/100 + values.repairFee;
    }
    maintainFeeTotal = maintainFeeTotal + values.brokerageFee;
    setMaintainFeeTotal(maintainFeeTotal);
    let rentIncomeTotal = 0;
    for (let i = 0; i < values.period; i++) {
      rentIncomeTotal = (rentIncomeTotal + values.rentIncome * 12) * (1 + values.moneyValue/100);
    }
    setRentIncomeTotal(rentIncomeTotal);
    const case2Result = RELeftValue - maintainFeeTotal + rentIncomeTotal;
    setCase2Result(case2Result);
    const case2ResultToNow = case2Result / (1 + values.moneyValue/100) ** values.period;
    setCase2ResultToNow(case2ResultToNow);
    setDataCalculated(true);
    setRender(!render);
  };
  return (
    <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} form={form}
      onFinish={(values) => calc(values)}
      initialValues = {{
        period: 20,
        moneyValue: 3,
        REAppreciation: 8.5,
        REValue: 1000000,
        landTax: 0.6,
        brokerageFee: 0,
        repairFee: 1500,
        mortgageRate: 6,
        rentIncome: 1200,
        cash: 350000,
        investmentRate: 5,
      }}
    >
      <FormItem label={string("period")} name="period" note={string("years")}/>
      <FormItem label={string("moneyValue")} name="moneyValue" note="%"/>
      <FormItem label={string("REAppreciation")} name="REAppreciation" note="%"/>
      <FormItem label={string("REValue")} name="REValue"/>
      <Form.Item label={string("maintainFee")}></Form.Item>
      <FormItem label={string("landTax")} name="landTax" note="%"/>
      <FormItem label={string("brokerageFee")} name="brokerageFee"/>
      <FormItem label={string("repairFee")} name="repairFee"/>
      <FormItem label={string("mortgageRate")} name="mortgageRate" note="%"/>
      <FormItem label={string("rentIncome")} name="rentIncome"/>
      <FormItem label={string("cash")} name="cash"/>
      <FormItem label={string("investmentRate")} name="investmentRate"/>
      <Form.Item wrapperCol={{ span: 14, offset: 4, }}>
        <Space size="large">
          <Button type="primary" htmlType="submit" >{string("Submit")}</Button>
        </Space>
      </Form.Item>
      {dataCalculated && <>
        <Form.Item label={string("case1")}>
          <div>{string("periodLater", [period])}</div>
          {/* <div style={{display: "inline"}} dangerouslySetInnerHTML={{ __html: string("caseInvestment", [10000, 5000]) }} /> */}
          <div>{string("cashAndMonthPayment", [cash, monthPayment])}</div>
          <div>{string("investmentLeft", [investmentResult.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), investmentResultToNow.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')])}</div>
        </Form.Item>

        <Form.Item label={string("case2")}>
          <div> { string("periodLater", [period]) } </div>
          <div> { string("loadLeft", [loanLeft.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')]) } </div>
          <div> { string("REUpdatedValue", [REUpdatedValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')]) } </div>
          <div> { string("RELeftValue", [RELeftValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')]) } </div>
          <div> { string("maintainFeeTotal", [maintainFeeTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')]) } </div>
          <div> { string("rentIncomeTotal", [rentIncomeTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')]) } </div>
          <div> { string("case2Result", [case2Result.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), case2ResultToNow.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')]) } </div>

        </Form.Item>
      </>
      }
    </Form>
  )
};
export default App;