// This file is temporory to imitate all the pre-process like the response of the request
window.Zgg = window.Zgg ?? {};
Zgg.response = {
  language: "English",
  defaultPage: ['AddVocabulary', 'Vocabulary'],
  // defaultPage: ['Home'],
};
Zgg.flags = {
  showConsole: false,
};
