import { DatabaseTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu } from 'antd';
import React from 'react';
import LanguageDropdown from '../components/LanguageChangeButton';
import OldAppButton from '../components/OldAppButton';
import { selectNavigate, goto, subGoto, subGroupGoto  } from "../slices/navigateSlice";
import { useSelector, useDispatch } from "react-redux";
import HomeContent from "./Home";
import AddVocabulary from "../components/AddVocabulary";
import Investment from "../components/Investment";
import InterestCalculator from "../components/InterestCalculator";

const { Header, Content, Sider } = Layout;

const Vocabulary = () => {
  return (<div>This is an app learn vocabulary! </div>)
};

const ShowList = () => {
  return (<div>There will be a list for all vocabulary stored in database.</div>)
};

const ContentComponent = () => {
  const navigateState = useSelector(selectNavigate);
  const key = navigateState[0];
  switch(key) {
    case "Vocabulary":
      return <Vocabulary />;
    case "AddVocabulary":
      return <AddVocabulary />;
    case "AddVocabularyByDrama":
      return <AddVocabulary source="drama"/>;
    case "AddVocabularyByDict":
      return <AddVocabulary source="dict"/>;
    case "ShowList":
      return <ShowList />;
    case "Investment":
      return <Investment />;
    case "InterestCalculator":
      return <InterestCalculator />;
    default:
      return <div>Not Found</div>;
  }
};

const generateLabel = (list) => {
  return list.map(item => {
    if (!item.children) {
      return { ...item, label: Zgg.string(item.key) };
    } else {
      return { ...item, label: Zgg.string(item.key), children: generateLabel(item.children) };
    }
  })
};
const items = [
  { key: "Home" },
  { key: "Vocabulary", children: [
    { key:"AddVocabulary", icon: React.createElement(PlusSquareTwoTone), children: [
      { key: "AddVocabularyByDrama", icon: React.createElement(PlusSquareTwoTone) },
      { key: "AddVocabularyByDict", icon: React.createElement(PlusSquareTwoTone) },
    ] },
    { key:"ShowList", icon: React.createElement(DatabaseTwoTone) },
  ] },
  { key: "Investment" },
  { key: "InterestCalculator" },
  { key: "5" },
  { key: "6" },
  { key: "7" },
  { key: "8" },
  { key: "9" },
  { key: "10" },
  { key: "11" },
  { key: "12" },
  { key: "13" },
];

const HomeLayout = () => {
  const mainMenuItems = generateLabel(items);
  const navigateState = useSelector(selectNavigate);
  const dispatch = useDispatch();
  const onMainMenuClick = (item) => {
    if (item.onGroupClick) {
      const xxx = items.find(x => x.children?.find(y => y.key === item.key))?.key;
      dispatch(goto(xxx ? [item.key, xxx] : [item.key]));
    } else {
      dispatch(goto(item.keyPath));
    }
  };
  const onSideMenuClick = (item) => {
    if (item.onGroupClick) {
      dispatch(subGroupGoto([item.key]));
    } else {
      dispatch(subGoto(item.keyPath));
    }
  };
  const selectedMenuKey = navigateState.reci(1);
  const subMenu = mainMenuItems.find(item => item.key === selectedMenuKey) || [];
  // const sideMenuSlectedKeys = [navigateState.reci(3) || navigateState.reci(2) || ""];
  const [collapsed, setCollapsed] = React.useState(false);
  const sideMenuOpenKeys = collapsed ? [] : [navigateState.reci(2) || ""];
  const [siderClassNanme, setSiderClassNanme] = React.useState("site-layout-background");
  const [siderMaskClassNanme, setMaskClassNanme] = React.useState("");
  return (
    <Layout style={{minHeight: "100vh"}}>
      <Header className="header" style={{ position: 'fixed', zIndex: 100, width: '100%' }}>
        <div className="logo" />
        <Menu onClick={onMainMenuClick} theme="dark" mode="horizontal" defaultSelectedKeys={[selectedMenuKey]} items={mainMenuItems} style={{ marginInlineEnd: "100px" }}/>
        <div style={{position: "absolute", top: "0px", right: "5%"}}>
          <OldAppButton mode="horizontal"/>
          <LanguageDropdown mode="horizontal"/>
        </div>
      </Header>
      <Layout style={{ marginTop: 64 }}>
        {selectedMenuKey === "Home" ? <HomeContent /> : 
        (<>
          <Sider width={200}
            className={siderClassNanme}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              // console.log(broken);
              if (broken) {
                setSiderClassNanme("site-layout-background self-drawer");
              } else {
                setSiderClassNanme("site-layout-background");
                setMaskClassNanme("");
              }
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
              if (!collapsed && type === "clickTrigger") {
                setMaskClassNanme("after-self-draw");
              } else {
                setMaskClassNanme("");
              }
            }}
          >
            <Menu
              onClick={onSideMenuClick}
              mode="inline"
              selectedKeys={[navigateState.reci(3) || navigateState.reci(2) || ""]}
              openKeys={sideMenuOpenKeys}
              style={{
                height: '100%',
                borderRight: 0,
              }}
              items={subMenu.children} />
          </Sider>
          <div className={siderMaskClassNanme}/>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              {navigateState.slice().reverse().map((item, index) => {
                return <Breadcrumb.Item key={index}>{Zgg.string(item)}</Breadcrumb.Item>
              })}
            </Breadcrumb>
            <Content className="site-layout-background" style={{ padding: 24, margin: 0, minHeight: 280 }}>
              <ContentComponent />
            </Content>
          </Layout>
        </>)}
      </Layout>
    </Layout>
  )
};
export default HomeLayout;