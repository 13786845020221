import React from 'react';
import { Space, Table } from 'antd';
import { Button, Form, InputNumber } from 'antd';
const string = (s, pattern) =>  {return Zgg.string("InterestCalculator_" + s, pattern);}
let data = [];

const getTotalPayment = (mortgage, loanAmount) => {
  const monthlyInterest = mortgage / 1200;
  const totalPayment = loanAmount * (monthlyInterest / (1 - Math.pow(1 / (1 + monthlyInterest), 360)));
  return totalPayment;
};

const calc = (values) => {
  const totalPayment = getTotalPayment(values.mortgageRate, values.loan);
  let loanAmount = values.loan;
  const data = [];
  for (let i = 1; i <= 360; i++) {
    const interest = loanAmount * values.mortgageRate / 100 / 12;
    const payment = totalPayment - interest;
    const engingLoan = loanAmount - payment;
    data.push({
      key: i,
      month: i,
      loan: loanAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      interest: interest.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      payment: payment.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      total: totalPayment.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      engingLoan: engingLoan.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
    });
    loanAmount = engingLoan;
  }
  return data;
};
const FormItem = (props) => {
  return (
    <Form.Item label={props.label}>
      <Form.Item name={props.name} noStyle>
        <InputNumber min={props.min || 0} style={{ width: '100px' }}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <span className="ant-form-text" style={{ marginLeft: 8 }}> {props.note} </span>
    </Form.Item>
  )
}
const App = () => {
  const columns = [
    {
      title: string('month'),
      dataIndex: 'month',
      key: 'Month',
    },
    {
      title: string('BeginningLoan'),
      dataIndex: 'loan',
      key: 'Loan',
    },
    {
      title: string('interest'),
      dataIndex: 'interest',
      key: 'Interest',
    },
    {
      title: string('payment'),
      key: 'Payment',
      dataIndex: 'payment',
    },
    {
      title: string('total'),
      dataIndex: 'total',
      key: 'Total',
    },
    {
      title: string('engingLoan'),
      dataIndex: 'engingLoan',
      key: 'EngingLoan',
    },
  ];
  const [form] = Form.useForm();
  const [dataCalculated, setDataCalculated] = React.useState(false);
  const [render, setRender] = React.useState(false);
  const [totalPayment, setTotalPayment] = React.useState(0);
  const onfinish = (values) => {
    data = calc(values);
    setDataCalculated(true);
    setRender(!render);
    setTotalPayment(totalPayment);
  };
  return (
    <>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} form={form} onFinish={(values) => onfinish(values)} initialValues={{mortgageRate: 6, loan: 650000}}>
        <FormItem label={string("mortgageRate")} name="mortgageRate" note="%"/>
        <FormItem label={string("loan")} name="loan"/>
        <Form.Item wrapperCol={{ span: 14, offset: 4, }}>
          <Space size="large">
            <Button type="primary" htmlType="submit" >{string("Submit")}</Button>
          </Space>
        </Form.Item>
      </Form>
      {dataCalculated ? <div>{string("totalPayment") + totalPayment} </div> : null}
      {dataCalculated ? <Table columns={columns} dataSource={data} pagination={false}
      scroll={{ y: 400 }}/> : null}
    </>
  )
}
export default App;
export { getTotalPayment, calc };