window.Zgg = window.Zgg ?? {};
Zgg.dictionary = {
  English: {
    Vocabulary: "Vocabulary",
    Home: "Home",
    AddVocabulary: "Add Vocabulary",
    AddVocabularyByDrama: "Drama",
    AddVocabularyByDict: "Dictionary",
    ShowList: "show List",
    addvoc_main: "main",
    addvoc_meaning: "meaning",
    addvoc_type: "type",
    addvoc_vocabulary: "vocabulary",
    addvoc_root: "root",
    addvoc_prefix: "prefix",
    addvoc_postfix: "postfix",
    addvoc_other: "other",
    addvoc_source: "source",
    addvoc_drama: "drama",
    addvoc_life: "life",
    addvoc_tiktok: "tiktok",
    addvoc_dict: "dict",
    addvoc_500root: "500root",
    addvoc_method: "method",
    addvoc_special: "special",
    addvoc_odd: "odd",
    addvoc_note: "note",
    addvoc_note_extension: "note_extension",
    addvoc_according: "according",
    addvoc_Search: "Search",
    addvoc_word: "word",
    addvoc_Submit: "Submit",
    addvoc_reset: "reset",
    addvoc_default: "default",
    Old_APP: "Old APP",
    investment_period: "period",
    investment_moneyValue: "money value",
    investment_REAppreciation: "Real estate appreciation level",
    investment_REValue: "Real estate value",
    investment_maintainFee: "maintain fee",
    investment_landTax: "land tax",
    investment_brokerageFee: "brokerage fee (one time)",
    investment_repairFee: "repair fee / month",
    investment_mortgageRate: "mortgage rate",
    investment_years: "years",
    investment_rentIncome: "Rent Income / month",
    investment_cash: "cash at the beginning",
    investment_investmentRate: "investment rate",
    investment_Submit: "calculate",
    investment_case1: "case 1. invest directly",
    investment_case2: "case 2. invest in real estate",
    investment_periodLater: (pattern) => `In ${pattern[0]} years, `,
    investment_cashAndMonthPayment: (pattern) => `you start at ${pattern[0]} and invest ${pattern[1]} every month.`,
    investment_investmentLeft: (pattern) => `you will have ${pattern[0]} dollars at the end which equals ${pattern[1]} dollars at the beginning. `,
    investment_loadLeft: (pattern) => `you still own bank ${pattern[0]} dollars. `,
    investment_REUpdatedValue: (pattern) => `but your house values ${pattern[0]} dollars. `,
    investment_RELeftValue: (pattern) => `so if you sell your house at that time, you will have ${pattern[0]} dollars left. `,
    investment_maintainFeeTotal: (pattern) => `but during this years, you need to pay ${pattern[0]} dollars (as end time) for maintenance. `,
    investment_rentIncomeTotal: (pattern) => `also, during this years, you can earn ${pattern[0]} dollars (as end time) as rent. `,
    investment_case2Result: (pattern) => `In conclusion, you will have ${pattern[0]} dollars left which equals ${pattern[1]} dollars at the beginning. `,
    InterestCalculator_mortgageRate: "mortgage rate",
    InterestCalculator_loan: "loan",
    InterestCalculator_Submit: "Submit",
    InterestCalculator_totalPayment: "Total Monthly Payment: ",
    InterestCalculator_month: "Month",
    InterestCalculator_BeginningLoan: "Beginning Loan Amount",
    InterestCalculator_interest: "Monthly Interest",
    InterestCalculator_payment: "Principal Payment",
    InterestCalculator_total: "Total Monthly Payment",
    InterestCalculator_engingLoan: "Enging Loan Amount",
  },
  Chinese: {
    Vocabulary: "单词",
    Home: "主页",
    AddVocabulary: "添加单词",
    AddVocabularyByDrama: "电视剧",
    AddVocabularyByDict: "字典",
    ShowList: "查看列表",
    addvoc_main: "主体",
    addvoc_meaning: "含义",
    addvoc_type: "类型",
    addvoc_vocabulary: "单词",
    addvoc_root: "词根",
    addvoc_prefix: "前缀",
    addvoc_postfix: "后缀",
    addvoc_other: "其他",
    addvoc_source: "来源",
    addvoc_drama: "电视剧",
    addvoc_life: "生活",
    addvoc_tiktok: "抖音",
    addvoc_dict: "字典",
    addvoc_500root: "500词根词缀",
    addvoc_method: "记忆方式",
    addvoc_special: "特别",
    addvoc_odd: "奇技淫巧",
    addvoc_note: "注释",
    addvoc_note_extension: "额外注释",
    addvoc_according: "前置单词",
    addvoc_Search: "搜索",
    addvoc_word: "单词",
    addvoc_Submit: "提交",
    addvoc_reset: "重置",
    addvoc_default: "不选择",
    Old_APP: "旧版APP",
    investment_period: "周期",
    investment_moneyValue: "时间价值",
    investment_REAppreciation: "房产增值率",
    investment_REValue: "房产价格",
    investment_maintainFee: "维护费",
    investment_landTax: "土地税",
    investment_brokerageFee: "中介费（一次性）",
    investment_repairFee: "维修费 / 月",
    investment_mortgageRate: "贷款利息",
    investment_years: "年",
    investment_rentIncome: "租金收入 / 月",
    investment_cash: "初始资金",
    investment_investmentRate: "投资收益率",
    investment_Submit: "计算",
    investment_case1: "案例1. 直接投资",
    investment_case2: "案例2. 投资房产",
    investment_periodLater: (pattern) => `在${pattern[0]}年后，`,
    investment_cashAndMonthPayment: (pattern) => `你开始有${pattern[0]}，并且每月投资${pattern[1]}。`,
    investment_investmentLeft: (pattern) => `最后你会有${pattern[0]}美元，这等于${pattern[1]}美元。`,
    investment_loadLeft: (pattern) => `你还有${pattern[0]}美元的贷款。`,
    investment_REUpdatedValue: (pattern) => `但是你的房子值${pattern[0]}美元。`,
    investment_RELeftValue: (pattern) => `所以如果你在那时候卖房子，你会有${pattern[0]}美元。`,
    investment_maintainFeeTotal: (pattern) => `但是在这几年里，你需要支付${pattern[0]}美元（按到期时算）的维护费。`,
    investment_rentIncomeTotal: (pattern) => `此外，在这几年里，你可以赚取${pattern[0]}美元（按到期时算）的租金。`,
    investment_case2Result: (pattern) => `总之，你会有${pattern[0]}美元，这等于${pattern[1]}美元。`,
    InterestCalculator_mortgageRate: "贷款利息",
    InterestCalculator_loan: "贷款",
    InterestCalculator_Submit: "提交",
    InterestCalculator_totalPayment: "月付款: ",
    InterestCalculator_month: "月",
    InterestCalculator_BeginningLoan: "初始贷款金额",
    InterestCalculator_interest: "月利息",
    InterestCalculator_payment: "本金付款",
    InterestCalculator_total: "总付款",
    InterestCalculator_engingLoan: "贷款余额",
  }
};