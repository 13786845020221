import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: Zgg.response.defaultPage,
};

export const navigateSlice = createSlice({
  name: 'navigate',
  initialState,
  reducers: {
    goto: (state, action) => {
      state.value = action.payload;
    },
    subGroupGoto: (state, action) => {
      if (state.value.equal([...action.payload, state.value.reci(1)])) {
        state.value = [state.value.reci(1)];
      } else {
        state.value = [...action.payload, state.value.reci(1)];
      }
    },
    subGoto: (state, action) => {
      state.value = [...action.payload, state.value.reci(1)];
    },
  },
});

export const { goto, subGoto, subGroupGoto } = navigateSlice.actions;
export const selectNavigate = (state) => state.navigate.value;

export default navigateSlice.reducer;
